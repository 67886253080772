import React, { useState, useEffect } from 'react';
//import data from '../data.json';
import data from '../data3.json';
import "./theme.css";
import axios from 'axios';
import ReactApexChart from "react-apexcharts";
//import mqtt from 'mqtt/dist/mqtt';
//import { Connector, subscribe } from 'react-mqtt-client'


// const MessageList = (props) => {
//   const { data } = props;

//   return (
//     <>
//       {data.reverse().map((d, i) => (
//         <p key={i}>{`${JSON.stringify(d, null, 4)}`}</p>
//       ))}
//     </>
//   );
// };

// const Connected = subscribe({ topic: 'radar/data' })(MessageList);


const MyRadarView = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [index, setIndex] = useState(0);

  const [intervalTime, setIntervalTime] = useState(10);
  const [menuOpen, setMenuOpen] = useState(false);
  const [lane, setLane] = useState([]);
  const [totalWidth, setTotalWidth] = useState(750);
  const [totalLength, setTotalLength] = useState(500);
  const [result, setResult] = useState(0);
  const [radarPosition, setRadarPosition] = useState(10);
  const [unique, setUnique] = useState([0]);
  const [path,setPath]=useState([])
  const [showChart,setshowChart]=useState(false)
  const [counted,setCount]=useState(0);

  const options ={
    chart: {
      height: 550,
      type: 'scatter',
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true
      }

    },
    theme: {
      mode: 'dark', 
      palette: 'palette1', 
      monochrome: {
          enabled: false,
          color: '#255aee',
          shadeTo: 'light',
          shadeIntensity: 0.65
      },
  },
    colors: ['#056BF6', '#D2376A'],
    xaxis: {
      tickAmount: 5,
      min: 0,
      max: totalWidth,
    
    },
    yaxis: {
      tickAmount: 4,
      min: 0,
      max: totalLength,     
    },
    grid: {
      show: false,      
      xaxis: {
        lines: {
          show: false  
         }
       },  
      yaxis: {
        lines: { 
          show: false  
         }
       },   
    },

    markers: {
      size: 12,            
      strokeColors: '#000',
      strokeWidth: 0,
    },
    fill: {
      type: 'image',
      opacity: 1,
      image: {
        src: ['https://img.icons8.com/fluency/48/null/car.png', 'https://img.icons8.com/fluency/48/null/bus.png','https://img.icons8.com/fluency/48/null/car.png'],
        width: 20,
        height: 20
      }
    },
    
    legend: {
      labels: {
        useSeriesColors: true
      },
      markers: {
        customHTML: [
          function() {
            return ''
          }, function() {
            return ''
          }
        ]
      }
    },
    annotations: {
      xaxis: lane
    }
  }
  

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios('http://localhost:5000/api/settings');
      setResult(response.data);
    };
    fetchData();
  },[]);

  useEffect(()=>{
    if(result){
      setTotalWidth(result[1].total_width);
      setTotalLength(result[1].total_length);
      setRadarPosition(result[1].radar_position*750/totalWidth);
      console.log("radar position",radarPosition)
      let list=[];
      let roadWid=result[1].road_width.split(',')
      // let roadWid=[0,200,250,100,100,100]
      roadWid.shift();
      console.log(roadWid);
      let arr=[];
      roadWid.forEach((item,i) => {
        // let width=list.length==0?(item*750)/totalWidth:((item*750)/totalWidth)+(list[i-1].x)
        i==0?arr.push(parseInt(item)):arr.push(parseInt(item)+arr[i-1]);
        let item1= {
          x: arr[i],
          borderColor: '#fff',                
        }
        list.push(item1)
      });
      list.push({
        x:radarPosition,
        borderColor:'orange'
      })
      list.push({
        x:0,
        borderColor:'#fff'
      })
      setLane(list)
      console.log(list,arr)
      //setShowRoad(true)
    }
    }, [result, totalWidth, totalLength]);

    useEffect(() => {
      let count=0;
      const interval=setInterval(() => {
      setCount(count);
         console.log(count)
              let existingRec=[];
              if(path.length){
                  console.log('data exist',path)
                  existingRec=path
                  let record=path.filter((p)=>p.name== 'car_'+data[count].ID)
                  if(record.length){
                      path.forEach(e => {
                          if(e.name=='car_'+data[count].ID){
                              e.data=[[(data[count].Rx),data[count].Ry]]
                          }
                      });
                  }
                  else{
                      let newData={
                          name:'car_'+data[count].ID,
                          data:[[data[count].Rx,data[count].Ry]]
                      }
                      existingRec.push(newData)
                      setPath(existingRec)
                  }
              }
              else {
                  let newData={
                      name:'car_'+data[count].ID,
                      data:[[data[count].Rx,data[count].Ry]]
                  }
                  existingRec.push(newData)
                  setPath(existingRec)
              }
              console.log(path)
              setshowChart(true);
              count=count+1;
              if(data.length==count){
                clearInterval(interval)
              } 
      }, 100);
      return () => clearInterval(interval);
  },[]);

//   <Connector
//   mqttProps={{
//     url: 'ws://https://test.mosquitto.org:8080',
//     options: { protocol: 'ws' }, // see MQTTjs options
//   }}
// >
//   <Connected />
// </Connector>


  return (
    <div>

      <header className="header">Radar View</header>
      <button onClick={toggleMenu} className={`menu-button ${menuOpen ? "open" : ""}`}>
        <span className="menu-icon"></span>
      </button>
      <div className={`side-menu ${menuOpen ? "open" : ""}`}>
        <a href="/radar-view">Radar View</a>
        <a href="/settings">Settings</a>
      </div>

      <div className="image-container" style={{width:'750px'}}>
      <div id="chart">
        { showChart && <ReactApexChart options={options} series={path} type="bubble" height={420} width={750}/> } 
      </div>
      </div>

      <footer className="footer">Copyright © 2023</footer>

    </div>
  );
}

export default MyRadarView;
