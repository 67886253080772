import React, { useState, useEffect } from 'react';
import "./theme.css";
import axios from 'axios';

const Settings = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [settings, setSettings] = useState([]);
  const [formData, setFormData] = useState({
    totalLength: '',
    totalWidth: '',
    radarPosition: '',
    numRoads: '',
    roadWidth: []
  });

  useEffect(() => {
    // Fetch all data from the API
    axios.get('http://localhost:5000/api/settings')
      .then(res => setSettings(res.data))
      .catch(err => console.error(err));
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const handleInputChange = event => {
    const { name, value } = event.target;
    if(name === "numRoads") {
      const roadWidthArr = Array.from({ length: value }, () => '');
      // console.log()
      setFormData({ ...formData, [name]: value, roadWidth: roadWidthArr });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleRoadWidthChange = (event, index) => {
    const newRoadWidthArr = [...formData.roadWidth];
    newRoadWidthArr[index] = event.target.value;
    setFormData({ ...formData, roadWidth: newRoadWidthArr });
}

  const handleSubmit = event => {
    event.preventDefault();
    if (formData.id) {
      // Update the existing setting
      handleUpdate(formData);
    } else {
    // Create a new setting using the form data
    axios.post('http://localhost:5000/api/settings', formData)
      .then(res => {
        // Retrieve all settings from the server
        axios.get('http://localhost:5000/api/settings')
          .then(res => setSettings(res.data))
          .catch(err => console.error(err));
       // Reset the form data
        setFormData({
          totalLength: '',
          totalWidth: '',
          radarPosition: '',
          numRoads: '',
          roadWidth: []
        });
      })
      .catch(err => console.error(err));
  }
};

  const handleDelete = id => {
    // Delete the setting with the specified ID
    axios.delete(`http://localhost:5000/api/settings/${id}`)
      .then(res => {
        // Remove the setting from the list of settings
        setSettings(settings.filter(setting => setting.id !== id));
      })
      .catch(err => console.error(err));
  };

  const handleUpdate = setting => {
    // Update the setting with the specified ID
    axios.put(`http://localhost:5000/api/settings/${setting.id}`, setting)
      .then(res => {
        // Replace the old setting with the updated one in the list of settings
        setSettings(settings.map(s => s.id === setting.id ? setting : s));
      })
      .catch(err => console.error(err));
  };

  return (
    <div>
      <header className="header">Settings</header>

      <button onClick={toggleMenu} className={`menu-button ${menuOpen ? "open" : ""}`}>
        <span className="menu-icon"></span>
      </button>
      <div className={`side-menu ${menuOpen ? "open" : ""}`}>
        <a href="/radar-view">Radar View</a>
        <a href="/settings">Settings</a>
      </div>

      <form className='form' onSubmit={handleSubmit}>
        <label>
          Total Length:
          <input type="number" name="totalLength" value={formData.totalLength} onChange={handleInputChange} />
        </label>
        <br />
        <label>
          Total Width:
          <input type="number" name="totalWidth" value={formData.totalWidth} onChange={handleInputChange} />
        </label>
        <br />
        <label>
          Radar Position:
          <input type="text" name="radarPosition" value={formData.radarPosition} onChange={handleInputChange} />
        </label>
        <br />
        <label>
          Number of Roads:
          <input type="number" name="numRoads" value={formData.numRoads} onChange={handleInputChange} />
        </label>
        <br />
        {formData.numRoads > 0 &&
    <div>
      {Array.from({ length: formData.numRoads }, (_, i) => i + 1).map(index => (
        <div key={index}>
          <label>
            Width of Road {index}:
            <input type="number" 
            name={`roadWidth${index}`} 
            value={formData.roadWidth[index] ? Number(formData.roadWidth[index]) : ''}
            onChange={(event) => handleRoadWidthChange(event, index)} />
            </label>
          <br />
        </div>
      ))}
    </div>
  }

        <button className='button' onClick={handleSubmit}>Save</button>
        {/* <button onClick={handleDelete}>Delete</button> */}
      </form>
      <br/>
      <br/>
      <table className='table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Total Length</th>
            <th>Total Width</th>
            <th>Radar Position</th>
            <th>Number of Roads</th>
            <th>Width of Each Road</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {settings.map(setting => (
            <tr key={setting.id}>
              <td>{setting.id}</td>
              <td>{setting.total_length}</td>
              <td>{setting.total_width}</td>
              <td>{setting.radar_position}</td>
              <td>{setting.num_roads}</td>
              <td>{setting.road_width}</td>
              <td>
                {/* <button onClick={() => handleUpdate(setting)}>Edit</button> */}
                <button onClick={() => handleDelete(setting.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default Settings;
