import React, { useState, useEffect } from 'react';
//import data from '../data.json';
import data from '../data3.json';
import "./theme.css";
import axios from 'axios';
import mqtt from 'mqtt/dist/mqtt';
//import { Connector, Connected } from 'react-mqtt-client'



//const mqtt = require ("precompiled-mqtt");
//var client = mqtt.connect('mqtt://broker.hivemq.com');

const RadarView = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [index, setIndex] = useState(0);
  const [intervalTime, setIntervalTime] = useState(1000);
  const [menuOpen, setMenuOpen] = useState(false);
  //const [showRoad, setShowRoad] = useState(false);
  const [lane, setLane] = useState([]);
  const [totalWidth, setTotalWidth] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [result, setResult] = useState(0);
  const [radarPosition, setRadarPosition] = useState(0);
  
  const client = mqtt.connect('mqtt://broker.hivemq.com');

  // <Connector
  //       mqttProps={{
  //           url: 'ws://https://localhost:9001',
  //           options: { protocol: 'ws' }, // see MQTTjs options
  //       }}
  //   >
  //       <Connected />
  //   </Connector>

  useEffect(() => {

    client.subscribe('radar/data');
    client.on('message',(topic,message)=>{
      debugger;
      const testMqttData = JSON.parse(message.toString());
      console.log("test data",testMqttData);
    });
    return ()=>client.end();
    const interval = setInterval(() => {
      const currentData = data[index];
      const nextData = data[(index + 1) % data.length];

      setIntervalTime(new Date(nextData.Time) - new Date(currentData.Time));

      // setPosition({ x: currentData.TarDisLong, y: currentData.TarDisLat });
      setPosition({ x: radarPosition+currentData.Rx-22.5, y: data[0].Ry-currentData.Ry+60 });
      console.log("radarPosition", radarPosition)
      console.log("Currentdata Rx, Ry",currentData.Rx, currentData.Ry)
      console.log("x, y",position.x, position.y)

      setIndex((prevIndex) => (prevIndex + 1) % data.length);

      // Clear interval if there's no more data
      if (index === data.length - 1) {
        clearInterval(interval);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [index]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  
    useEffect(() => {
    const fetchData = async () => {
      const response = await axios('http://localhost:5000/api/settings');
      setResult(response.data);
    };
    fetchData();
  },[]);

  useEffect(()=>{
    if(result){
      setTotalWidth(result[1].total_width);
      setTotalLength(result[1].total_length);
      setRadarPosition(result[1].radar_position*750/totalWidth);
      console.log("radar position",radarPosition)
      let list=[];
      let roadWid=result[1].road_width.split(',')
      roadWid.shift();
      roadWid.forEach((item,i) => {
        let width=list.length==0?(item*750)/totalWidth:((item*750)/totalWidth)+(list[i-1].x)
        let item1={
          x:width,
          y:0
        };
        list.push(item1)
      });
      setLane(list)
      //setShowRoad(true)
    }
    }, [result, totalWidth, totalLength]);

  
  

  return (
    <div>

      <header className="header">Radar View</header>

      <button onClick={toggleMenu} className={`menu-button ${menuOpen ? "open" : ""}`}>
        <span className="menu-icon"></span>
      </button>
      <div className={`side-menu ${menuOpen ? "open" : ""}`}>
        <a href="/radar-view">Radar View</a>
        <a href="/settings">Settings</a>
      </div>
      
      <div className="image-container" style={{width:'750px'}}>
      {
        data[index].Class==1 ? (
      <img
        src="https://img.icons8.com/fluency/48/null/car.png"
        alt="car icon"
        style={{ 
          position: 'absolute', 
          width: '50px', 
          height: '50px', 
          left: `${position.x}px`, 
          top: `${position.y}px`,
          zIndex: 1000 }}
      />
        ):(
      
        <img
        src="https://img.icons8.com/fluency/48/null/bus.png"
        alt="car icon"
        style={{ 
          position: 'absolute', 
          width: '50px', 
          height: '50px', 
          left: `${position.x}px`, 
          top: `${position.y}px`,
          zIndex: 1000 }}
          />
      )}
      
  {
    lane.map((pos, index) => {
     

     return (<div  key={index} className={`line line-${index}`} 
       style={{ 
         position: 'absolute', 
         left: `${pos.x}px`, 
         top: `${pos.y}px`, 
         width: '2px', 
         height: `1000px`, 
         backgroundColor: 'white' 
         }}
         ></div>)
 })
 }


  <div className='radar'
  style={{ 
         position: 'absolute', 
         left: `${radarPosition}px`, 
         top: `0px`, 
         width: '2px', 
         height: `1000px`, 
         backgroundColor: 'pink'
         }}
  >
  </div>


      <div className='arrow middle-down'></div>
      </div>

      <footer className="footer">Copyright © 2023</footer>

    </div>
  );
}

export default RadarView;
