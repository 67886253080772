import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MyRadarView from './components/myRadar';
import RadarView from './components/RadarView';
import Settings from './components/Settings';
//import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
      </header>
      <Router>
        <Routes>
          <Route path="/radar-view" element={<RadarView />} />
          <Route path="/radar-view-2" element={<MyRadarView />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

function Home() {
  return <h1>Home Page</h1>;
}

export default App;
